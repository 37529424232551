import { Grid, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import useDataTableDetailOrderController from "modules/my-sales/submodules/orders/list/hooks/useDataTableDetailOrderController";
import { getEsimQr } from "_models/redux/esim-qr/action";
import { municipalityAction } from "_models/redux/sepomex-municipality/action";
import { neighborhoodAction } from "_models/redux/sepomex-neighborhood/action";
import { getSaleOrderDetailAction } from "_models/redux/sales-detail-order/action";
import { cleanSaleOrderDetailAction } from "_models/redux/sales-detail-order/clean-action";
import { cleanChangeSimToEsimAction } from "_models/redux/change-sim-esim/clean-action";
import { cleanChangeEsimToSimAction } from "_models/redux/change-esim-sim/clean-action";
import { changeSimToEsimAction } from "_models/redux/change-sim-esim/action";
import { changeEsimToSimAction } from "_models/redux/change-esim-sim/action";
import { simActivationAction } from "_models/redux/sim-activation/action";
import { postTransactionsChargeback } from "_models/redux/detail-transaction-chargeback/action";
import { postTransactionsFraud } from "_models/redux/detail-transaction-fraud/action";
import { cleanTransactionChargeback } from "_models/redux/detail-transaction-chargeback/clean_action";
import { cleanTransactionFraud } from "_models/redux/detail-transaction-fraud/clean_action";
import { getSaleOrdersAction } from "_models/redux/sales-orders/action";
import PurchaseSummary from "modules/my-sales/submodules/orders/list/components/PurchaseSummary";
import AdditionalInfoSection from "./componentsDetails/AdditionalInfoSection";
import FraudSection from "./componentsDetails/FraudSection";
import IdentifiersSection from "./componentsDetails/IdentifiersSection";
import PayerInfo from "./componentsDetails/PayerInfo";
import SummarySectionWrapper from "./componentsDetails/SummarySectionWrapper";
import TransactionCardWrapper from "./componentsDetails/TransactionCardWrapper";

const DataTableDetailOrder = (props) => {
    const {
        response,
        handleOpenPopUp,
        type,
        onClose,
        namePayment,
        setIdReverso,
        idTransaction,
    } = props || {};
    const controllerProps = useDataTableDetailOrderController(props);
    const [title, setTitle] = useState("Tipo de Transacción");
    const fraudStyle = {
        fontWeight: 800,
        fontSize: "17px",
        color: "#ef5350",
    };
    const chargebackStyle = {
        fontWeight: 800,
        fontSize: "17px",
        color: "#f57c00",
    };
    const isLoading = !response;
    const hasPaymentInfo = response?.payment_info !== null;
    let orderDetails;
    switch (type) {
        case "detailsHistoryPrepaid":
            orderDetails = response?.prepaid;
            break;
        case "detailsSimIpb":
            orderDetails = response?.ipb;
            break;
        case "detailPurchase":
            orderDetails = response?.purchase;
            break;
        default:
            orderDetails = response?.payment_info;
            break;
    }
    return (
        <>
            {isLoading && <CircularProgress />}
            <Grid
                container
                spacing={5}
                className="device-info-container m-b-15"
            >
                {!isLoading && (
                    <>
                        {hasPaymentInfo ? (
                            <>
                                <Grid item xs={6} direction={"column"}>
                                    <div style={{ marginTop: "20px" }}></div>
                                    <TransactionCardWrapper
                                        namePayment={namePayment}
                                        type={type}
                                        setTitle={setTitle}
                                        title={title}
                                        response={response}
                                        idTransaction={idTransaction}
                                        onClose={onClose}
                                        isLoading={isLoading}
                                        setIdReverso={setIdReverso}
                                    />
                                    <div style={{ marginTop: "20px" }}></div>
                                    {type === "detailPurchase" && (
                                        <PurchaseSummary
                                            product={orderDetails}
                                        />
                                    )}
                                    <div style={{ marginTop: "20px" }}></div>
                                    <AdditionalInfoSection
                                        type={type}
                                        orderDetails={orderDetails}
                                        controllerProps={controllerProps}
                                    />
                                    {type === "detailsSTP" && (
                                        <>
                                            <div
                                                style={{ marginTop: "20px" }}
                                            ></div>
                                            <PayerInfo
                                                type={type}
                                                title={
                                                    type ===
                                                    "detailsHistoryPrepaid"
                                                        ? "Detalles"
                                                        : type ===
                                                            "detailsSimIpb"
                                                          ? "Detalles del pedido"
                                                          : type ===
                                                              "detailsSTP"
                                                            ? "Información del Pagador"
                                                            : "Información Adicional"
                                                }
                                                orderDetails={orderDetails}
                                            />
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={6} direction={"column"}>
                                    {type !== "detailsSTP" && (
                                        <>
                                            <div
                                                style={{ marginTop: "20px" }}
                                            ></div>
                                            <PayerInfo
                                                type={type}
                                                title={
                                                    type ===
                                                    "detailsHistoryPrepaid"
                                                        ? "Detalles"
                                                        : type ===
                                                            "detailsSimIpb"
                                                          ? "Detalles del pedido"
                                                          : type ===
                                                              "detailsSTP"
                                                            ? "Información del Pagador"
                                                            : "Información Adicional"
                                                }
                                                orderDetails={orderDetails}
                                            />
                                        </>
                                    )}
                                    <div style={{ marginTop: "20px" }}></div>
                                    {type !== "detailsSimIpb" && (
                                        <IdentifiersSection
                                            type={type}
                                            orderDetails={orderDetails}
                                        />
                                    )}
                                    <div style={{ marginTop: "20px" }}></div>
                                    <FraudSection
                                        handleOpenPopUp={handleOpenPopUp}
                                        type={type}
                                        orderDetails={orderDetails}
                                        controllerProps={controllerProps}
                                        fraudStyle={fraudStyle}
                                        chargebackStyle={chargebackStyle}
                                    />
                                    <div style={{ marginTop: "20px" }}></div>
                                    <SummarySectionWrapper
                                        type={type}
                                        controllerProps={controllerProps}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Typography variant="h6" color="textSecondary">
                                No hay información disponible.
                            </Typography>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => ({
    saleOrderDetail: state?.saleOrderDetail,
    saleOrderEsimQr: state?.saleOrderEsimQr,
    municipality: state?.municipality,
    neighborhood: state?.neighborhood,
    changeEsimToSim: state?.changeEsimToSim,
    changeSimToEsim: state?.changeSimToEsim,
    simActivation: state?.simActivation,
    detailTransactionFraud: state?.detailTransactionFraud,
    detailTransactionChargeback: state?.detailTransactionChargeback,
});
const mapDispatchToProps = {
    getEsimQr,
    municipalityAction,
    neighborhoodAction,
    getSaleOrderDetailAction,
    cleanSaleOrderDetailAction,
    cleanChangeSimToEsimAction,
    cleanChangeEsimToSimAction,
    changeSimToEsimAction,
    changeEsimToSimAction,
    simActivationAction,
    postTransactionsChargeback,
    postTransactionsFraud,
    cleanTransactionChargeback,
    cleanTransactionFraud,
    getSaleOrdersAction,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataTableDetailOrder);
